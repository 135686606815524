import { callGet, callPost } from '../axios';


export const addToCart = async ({ userId, productId, quantity, productType, productPrice = 0, image }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    let cartData = JSON.parse(localStorage.getItem("cart")) || [];
    const newCartItem = {
        productId: productId,
        quantity: quantity,
        productType: productType,
        ProductPrice: productPrice,
        image: image,
        type: 'add',
        itemId :Date.now()
    };
    let productExists = cartData?.find(item => item?.productId === newCartItem?.productId);
    if (!productExists) {
        cartData.push(newCartItem);
    } else {
        let index = cartData?.findIndex(item => item?.productId === newCartItem?.productId);
        if (index !== -1) {
            cartData[index].quantity += quantity;
        }
    }

    if (!token) {
        localStorage.setItem("cart", JSON.stringify(cartData));
        return "Product added to cart successfully!";
    } else {
        const token = JSON.parse(localStorage.getItem("token"));
        var bodyFormData = new FormData()
        bodyFormData.append('userId', userId)
        bodyFormData.append('productId', productId)
        bodyFormData.append('quantity', quantity)
        bodyFormData.append('productType', productType)
        bodyFormData.append('productPrice', productPrice)
        bodyFormData.append('type', 'add')
        return callPost(`/cart.php`, bodyFormData, token).then((res) => {
            localStorage.removeItem("cart")
            return res?.data?.data
        }).catch((err) => {
            return Promise.reject(err)
        })
    }
};


export const getCartCount = async ({ userId }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    return callGet(`/cart.php`, token, { type: 'getCount', userId }).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const getCartProduct = async (userId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    return callGet(`/cart.php`, token, { type: 'get', userId }).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const deleteCartProduct = async (cartId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    return callGet(`/cart.php`, token, { type: 'deleteCartProduct', cartId }).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const updateCart = async (cartId, quantity) => {
    const token = JSON.parse(localStorage.getItem("token"));
    return callGet(`/cart.php`, token, { type: 'updateCart', cartId, quantity }).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}